﻿<div class="no-padding home-bg" style="margin-bottom: -61px;">
  <div class="home-bg-trans1">
    <!-- Navigation Bar-->
    <header id="topnav">
      <div class="topbar-main" style="height: 60px;">
        <div class="container">
          <!-- Logo container-->
          <div class="logo">
            <a class="logo" [title]="isFarronResearch ? 'Farron Research Logo' : 'Logo'">
              <span class="hidden-xs"><img src="assets/images/fr-logo.png"
                  [alt]="isFarronResearch ? 'Farron Research Logo' : 'Logo'"></span>
              <span class="hidden-sm hidden-md hidden-lg">
                <img src="assets/images/fr-only-logo.png" [alt]="isFarronResearch ? 'Farron Research Logo' : 'Logo'">
              </span>
            </a>
          </div>
          <!-- End Logo container-->
          <div class="menu-extras">
            <ul class="nav navbar-nav navbar-right pull-right ax-right-nav-btn">
              <li>
                <div class="input-group">
                  <a class="btn btn-basil" [routerLink]="['/signin']">Login</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- End topbar -->
    </header>
    <!-- End Navigation Bar-->
    <!-- =======================
          ===== START PAGE ======
          ======================= -->

    <div class="wrapper login-page-wrapper">
      <div class="container min-width-100vw text-center">
        <div class="row">
          <!--<div class="col-sm-2"></div>-->
          <div class="ax-login-panel">
            <h3 class="login-card-header">
              <i class="fa fa-lock"></i> &#160;Set New Password
            </h3>
            <div class="col-md-12">
              <div class="card-box m-b-0 login-card">
                <form class="form-horizontal ax-form" role="form" id="loginForm" #forgotPassForm="ngForm">
                  <div class="form-group">
                    <label class="control-label w-100-pre" style="text-align: left" for="newpassword">
                      New Password
                      <!-- <span [hidden]="newPwdModel.valid || !isSubmitForm">
                        <span class="text-danger pull-right"
                          [hidden]="!newPwdModel.hasError('required')">Required</span>
                        <span class="text-danger pull-right" [hidden]="!newPwdModel.hasError('pattern')">
                          At Least 8 characters with number, symbol and an uppercase letter&#160;</span>                        
                      </span> -->
                      <span class="pull-right" *ngIf="newPwdModel.dirty || (!newPwdModel.valid && isSubmitForm)"
                        [class.text-danger]="!newPwdModel.valid && isSubmitForm">
                        <span class="pull-right ax-cursor-pointer">
                          At Least 8 characters with number, symbol and an uppercase letter
                        </span>
                      </span>
                    </label>
                    <input type="password" name="newpassword" class="form-control" [(ngModel)]="newpassword"
                      placeholder="" #newPwdModel="ngModel" autocomplete="off" required [minlength]="minlength"
                      [pattern]="pattern" (ngModelChange)="confirmnewpasswordvalidation()" id="newpassword">
                    <!-- <input *ngIf="role != 'respondent'" type="password" name="newpassword" class="form-control" [(ngModel)]="newpassword" placeholder=""
                      #newPwdModel="ngModel" autocomplete="off" required minlength="6"> -->
                  </div>
                  <div class="form-group">
                    <label class="control-label w-100-pre" style="text-align: left" for="confirmnewpassword">
                      Confirm New Password
                      <!-- <span [hidden]="confirmPwdModel.valid || !isSubmitForm">
                        <span class="text-danger pull-right" [hidden]="!confirmPwdModel.hasError('required')">Required</span>
                        <span class="text-danger pull-right" [hidden]="!confirmPwdModel.hasError('minlength')">Password must contain at least 6 characters.</span>                        
                      </span> -->
                      <span class="text-danger pull-right" *ngIf="confrimpwderror">{{confrimpwderror}}</span>
                    </label>
                    <input type="password" name="confirmnewpassword" class="form-control" placeholder=""
                      [(ngModel)]="confirmnewpassword" #confirmPwdModel="ngModel" autocomplete="off" required
                      minlength="6" (ngModelChange)="confirmnewpasswordvalidation()" id="confirmnewpassword">
                  </div>
                  <div class="clearfix"></div>
                  <div class="text-right">
                    <button class="btn btn-basil waves-effect waves-light btn-md" (click)="setPassword(forgotPassForm)">
                      Set Password
                    </button>
                    <button class="btn btn-cocktail m-l-5" [routerLink]="['/signin']">Cancel</button>
                  </div>
                  <div class="clearfix"></div>
                  <div tabindex="0" class="text-center error-box" *ngIf="error">
                    <b>{{error}}</b>
                  </div>
                  <div class="clearfix"></div>
                </form>
              </div>
            </div>
          </div>
          <!--<div class="col-sm-2"></div>-->
        </div><!-- end container -->
      </div>
    </div>
  </div>
</div>