<div class="wrapper">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <ol class="breadcrumb breadcrumb-arrow bg-white bx-shadow">
          <li>
            <a [routerLink]="['/reshome']" class="home-breadcrumb">
              <i class="md md-home"></i>
            </a>
          </li>
          <li><a>My Opportunities</a></li>
        </ol>
      </div>
      <div class="col-sm-12">
        <div class="card-box respondent-card-box" *ngFor="let rs of ressurvey" style="position: relative;">
          <div style="display: flex;">
            <div style="flex: 1;">
              <!-- <p>{{rs?.expiryDate | date: 'dd-MM-yyyy hh:mm a'}}</p>               -->
              <p class="font-weight-700 font-16">{{rs?.clientJob?.inviteLocation}}</p>
              <p>{{rs?.clientJob?.jobNumberAndName}}</p>              
              <p class="font-12">{{rs?.clientJob?.inviteTime}}</p>              
              <p class="font-12">{{rs?.clientJob?.inviteJobType}}</p>              
            </div>
            <div style="flex: 1;max-width: 76px;justify-content: center;flex-direction: column;display: flex;" class="text-center">
              <!-- {{rs?.incentive}} -->
              <h3 class="m-t-0 m-b-0" style="color: #1f3f5a;">$75</h3>
              <h6 class="m-t-0 m-b-0" style="color: #1f3f5a;">Incentive</h6>              
              <a class="m-t-5 btn btn-basil btn-xs waves-effect btn-square" href="{{rs?.resSurveyURL}}">Start Survey</a>
            </div>
          </div>
        </div>

        <div class="card-box respondent-card-box" *ngIf="!isLoading && ressurvey && ressurvey?.length == 0">
          Sorry, opportunities not found. Complete as much detail as possible in your profile to ensure you get selected
          for Research.
        </div>

        <!-- <p-table #dt [columns]="selectedColumns" [value]="ressurvey" [paginator]="true" [paginatorPosition]="'top'"
            [pageLinks]="10" [rows]="noofrows" [totalRecords]="ressurvey?.length" [loading]="false"
            selectionMode="multiple" [(selection)]="selectedRowData" [metaKeySelection]="true"
            [resizableColumns]="true" columnResizeMode="expand"
            [tableStyleClass]="'table ax-table table-striped table-bordered m-b-0 border-color-e7e7e7'"
            [scrollable]="true" scrollHeight="550px">

            <ng-template pTemplate="caption">
              <div class="pull-left col-md-6 no-padding text-left">
                <div class="ax-sysconfig-data-table-top ax-data-table-top-x700 col-md-6 no-padding">
                  <div class="btn-group">
                  </div>
                </div>
              </div>

              <div class="pull-right col-md-6 no-padding text-right ax-primeng-table-right">
                <span class="ax-table-search vertical-align-top">
                  <span class="btn btn-default btn-xs btn-square btn-default-border vertical-align-top">
                    <i class="md md-search"></i>
                  </span>
                  <input [(ngModel)]="ptablesearch" type="text" pInputText size="50" placeholder="Search"
                    (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:150px"
                    class="vertical-align-top ptable-search-input">
                  <span class="btn btn-default btn-xs btn-square btn-default-border vertical-align-top"
                    (click)="dt.filterGlobal(null, 'contains');ptablesearch = null">
                    <i class="md md-clear"></i>
                  </span>
                </span>

                <span class="m-l-1 ax-table-filter vertical-align-top">
                  <select name="noofrows" class="btn-default-border vertical-align-top" [(ngModel)]="noofrows">
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="300">300</option>
                    <option value="500">500</option>
                    <option value="1000">1,000</option>
                  </select>
                </span>
                <span class="m-l-1 btn btn-default btn-xs btn-square btn-default-border vertical-align-top">
                  Total {{ressurvey?.length}} Records
                </span>
                <span class="m-l-1">
                  <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                    (onChange)="updateSelectedColumnsIndex()" selectedItemsLabel="Show Column" maxSelectedLabels="0"
                    [style]="{minWidth: 'auto'}" defaultLabel="Show Column" dropdownIcon='caret' [filter]="false"
                    [showToggleAll]="false" [showHeader]="false">
                  </p-multiSelect>
                </span>
              </div>
              <div class="clearfix"></div>
            </ng-template>

            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngFor="let col of columns" [style.width.px]="col?.width">
              </colgroup>
            </ng-template>

            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" pResizableColumn [style.width.px]="col?.width"
                  [style.textAlign]="col?.textAlign" [pSortableColumn]="col.sort ? col.field : ''">
                  {{col.header}}
                  <p-sortIcon *ngIf="col.sort" [field]="col.field" ariaLabel="Activate to sort"
                    ariaLabelDesc="Activate to sort in descending order"
                    ariaLabelAsc="Activate to sort in ascending order">
                  </p-sortIcon>
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rs let-i="rowIndex" let-columns="columns">
              <tr [pSelectableRow]="rs">
                <ng-container *ngFor="let sc of selectedColumns;">
                  <ng-container [ngSwitch]="sc.field">
                    <td *ngSwitchCase="'expiryDate'" [style.width.px]="sc.width" [style.textAlign]="sc?.textAlign">
                      {{rs?.expiryDate | date: 'dd-MM-yyyy hh:mm'}}</td>
                    <td *ngSwitchCase="'closed'" [style.width.px]="sc.width" [style.textAlign]="sc?.textAlign">
                      <span *ngIf="rs.closed" class="text-danger">
                        Closed
                      </span>
                      <a *ngIf="!rs.closed" class="text-success" target="_blank" href="{{rs?.resSurveyURL}}">
                        Start Survey
                      </a>
                    </td>
                    <ng-container *ngSwitchDefault>
                      <td [style.width.px]="sc.width" [style.textAlign]="sc?.textAlign">
                        {{resolveFieldData(rs, sc.field)}}</td>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td [attr.colspan]="columns.length">
                  No data available
                </td>
              </tr>
            </ng-template>
          </p-table> -->
      </div>
    </div>
  </div>
</div>