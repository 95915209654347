<div class="wrapper">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="card-box">
          <h3 class="text-left no-margin">
            <i class="md md-warning"></i>
            <span class="text-danger"> Page not found</span>
          </h3>
          <h5 class="m-b-0">Sorry the page you were looking for could not be found</h5>
        </div>
      </div>
    </div>
  </div>
</div>
