﻿<div class="no-padding home-bg" style="margin-bottom: -61px;">
    <div class="home-bg-trans1">
        <!-- Navigation Bar-->
        <header id="topnav">
            <div class="topbar-main" style="height: 60px;">
                <div class="container">
                    <!-- Logo container-->
                    <div class="logo">
                        <a class="logo" [title]="isFarronResearch ? 'Farron Research Logo' : 'Logo'">
                            <span class="hidden-xs"><img src="assets/images/fr-logo.png"
                                    [alt]="isFarronResearch ? 'Farron Research Logo' : 'Logo'"></span>
                            <span class="hidden-sm hidden-md hidden-lg">
                                <img src="assets/images/fr-only-logo.png"
                                    [alt]="isFarronResearch ? 'Farron Research Logo' : 'Logo'">
                            </span>
                        </a>
                    </div>
                    <!-- End Logo container-->
                    <div class="menu-extras">
                        <ul class="nav navbar-nav navbar-right pull-right ax-right-nav-btn">
                            <li>
                                <div class="input-group">
                                    <a class="btn btn-basil" [routerLink]="['/signin']">Login</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- End topbar -->
        </header>
        <!-- End Navigation Bar-->
        <!-- =======================
      ===== START PAGE ======
      ======================= -->

        <div class="wrapper login-page-wrapper">
            <div class="container min-width-100vw text-center">
                <div class="row">
                    <!--<div class="col-sm-2"></div>-->
                    <div class="ax-login-panel" style="max-width: 675px;">
                        <h3 class="login-card-header" *ngIf="!successMsg">Are you sure?</h3>
                        <div class="col-md-12">
                            <div class="card-box m-b-0" [class.login-card]="!successMsg">
                                <ng-container *ngIf="!successMsg">
                                    <h4 class="font-normal m-t-0">You will be unsubscribed from our system and no longer
                                        will be contacted.</h4>
                                    <div class="clearfix"></div>
                                    <div class="text-right">
                                        <button class="btn btn-ocean waves-effect waves-light btn-md"
                                            (click)="unsubscribe()">
                                            Yes
                                        </button>
                                        <button type="button" class="btn btn-cocktail m-l-5"
                                            [routerLink]="['/']">No</button>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="successMsg">
                                    <h4 class="font-normal m-t-0  m-b-0">{{successMsg}}</h4>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <!--<div class="col-sm-2"></div>-->
                </div><!-- end container -->
            </div>
        </div>
    </div>
</div>