﻿<div class="no-padding home-bg {{surveyTheme}}" style="margin-bottom: -61px;">
  <div class="home-bg-trans1">
    <!-- Navigation Bar-->
    <header id="topnav">
      <div class="topbar-main" style="height: 60px;">
        <div class="container">
          <!-- Logo container-->
          <div class="logo">
            <a class="logo" [title]="isFarronResearch ? 'Farron Research Logo' : 'Logo'">
              <span class="hidden-xs"><img src="assets/images/fr-logo.png"
                  [alt]="isFarronResearch ? 'Farron Research Logo' : 'Logo'"></span>
              <span class="hidden-sm hidden-md hidden-lg">
                <img src="assets/images/fr-only-logo.png" [alt]="isFarronResearch ? 'Farron Research Logo' : 'Logo'">
              </span>
            </a>
          </div>
          <!-- End Logo container-->
          <div class="menu-extras">
            <ul class="nav navbar-nav navbar-right pull-right ax-right-nav-btn">
              <li>
                <!-- <div class="input-group">
                  <a class="btn btn-skyblue" [routerLink]="['/signin']">Login</a>
                </div> -->
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- End topbar -->
    </header>
    <!-- End Navigation Bar-->
    <!-- =======================
          ===== START PAGE ======
          ======================= -->

    <div class="wrapper login-page-wrapper">
      <div class="container min-width-100vw">
        <div class="container text-center">
          <div class="row">
            <div class="ax-login-panel">
              <div class="col-md-12">
                <div *ngIf="errors.length > 0" class="card-box m-b-0">
                  <!-- <h3 class="text-left no-margin">
                    <i class="md md-warning"></i>
                    <span class="text-danger"> Something went wrong</span>
                  </h3> -->
                  <h5 class="m-b-0 m-t-0 text-center text-danger">{{errors[0]}}</h5>
                </div>
                <div *ngIf="errors.length < 1" class="card-box m-b-0">
                  <h2 style="font-size: 18px; margin: 0;">Dear {{landingPageData?.respondentName}},</h2>
                  <div [innerHTML]="landingPageData?.inviteDetailedDescription" class="m-b-20"></div>
                  <div class="text-center">
                    <h3 class="m-b-20 section-header">
                      <img *ngIf="surveyTheme != 'survey-theme-green'" height="25"
                        src="https://cdn4.iconfinder.com/data/icons/social-messaging-ui-color-and-shapes-6/177800/294-512.png"
                        width="25">
                      {{landingPageData?.jobIncentive}}
                    </h3>
                    <div class="text-center m-b-20">
                      <img src="{{landingPageData?.inviteImageUrl}}"
                        style="width: 100%;height: 200px;object-fit: cover;">
                    </div>
                    <p class="m-b-20" style="font-size: 13px;">
                      If you are interested in participating in this research, please click Continue to Survey to see if you
                      are
                      eligible.
                    </p>
                  </div>
                  <hr>
                  <div>
                    <h3 style="font-size: 16px;margin: 0;">Session Details</h3>
                    <ul class="session-list">
                      <li>
                        <img alt="Session Type" title="Session Type"
                          src="https://secure.farronresearch.com.au/assets/images/email/user.png"
                          style="height:20px; width:20px">&nbsp;
                        {{landingPageData?.inviteJobType}}
                      </li>
                      <li>
                        <img alt="Session Time" title="Session Type"
                          src="https://secure.farronresearch.com.au/assets/images/email/clock.png"
                          style="height:20px; width:20px">&nbsp;
                        {{landingPageData?.inviteTime}}
                      </li>
                      <li>
                        <img alt="Session Date" title="Session Date"
                          src="https://secure.farronresearch.com.au/assets/images/email/calendar.png"
                          style="height:20px; width:20px">&nbsp;
                        {{landingPageData?.inviteDate}}
                      </li>
                      <li>
                        <img alt="Session Location" title="Session Location"
                          src="https://secure.farronresearch.com.au/assets/images/email/location.png"
                          style="height:20px; width:20px">&nbsp;
                        {{landingPageData?.inviteLocation}}
                      </li>
                      <li *ngIf="landingPageData?.studyDetails">
                        <!-- <img alt="Study Details" title="Study Details"
                          src="https://secure.farronresearch.com.au/assets/images/email/location.png"
                          style="height:20px; width:20px">&nbsp; -->
                        <i class="ti ti-search" style="font-size: 19px;color: #000;vertical-align: bottom;"></i>&nbsp;
                        {{landingPageData?.studyDetails}}
                      </li>
                    </ul>
                  </div>
                  <hr>
                  <p class="m-b-15 text-center">The survey will take approximately
                    {{landingPageData?.surveyDuration}}. Do
                    you want to proceed?</p>
                  <div class="row-1">
                    <ng-container *ngIf="!isDecline">
                      <div class="row">
                        <div class="col-md-6">
                          <!-- (click)="isDecline = true;" -->
                          <button class="btn btn-cocktail btn-block font-16" data-toggle="modal" data-backdrop="static"
                            data-target="#declineModal" (click)="declineSurveyFormError = []">Decline this
                            Survey</button>
                        </div>
                        <div class="col-md-6">
                          <!-- <button class="btn btn-basil btn-block font-16"
                            [routerLink]="['/survey-welcome', encodeddata]">Start
                            Survey</button> -->
                          <button class="btn btn-basil btn-block font-16 mobile-m-t-10"
                            [routerLink]="['/survey/survey-start', encodeddata]">Continue to Survey</button>
                        </div>
                      </div>
                    </ng-container>
                    <!-- <ng-container *ngIf="isDecline">
                    <form class="ax-form ax-color-black">
                      <div *ngFor="let opt of landingPageData?.declineOptions;let i = index" class="radio radio-primary"
                        style="margin-right: 15px;display: inline-block;">
                        <input type="radio" name="declineOptions" id="bouncemail{{i}}" [value]="opt"
                          [(ngModel)]="declineOption">
                        <label class="control-label" for="bouncemail{{i}}">{{opt}}</label>
                      </div>
                      <div *ngIf="declineOption == 'Other'" class="form-group">
                        <label for="">Reason</label>
                        <input type="text" name="" class="form-control">
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <button class="btn btn-cocktail font-16 btn-block" (click)="isDecline = false">Cancel</button>
                        </div>
                        <div class="col-md-6">
                          <button class="btn btn-basil font-16 btn-block">Start Survey</button>
                        </div>
                      </div>
                    </form>
                  </ng-container> -->
                    <div class="clearfix"></div>
                  </div>
                  <hr>
                  <div class="text-center">
                    <div *ngIf="landingPageData?.inviteSupportEmail" class="m-b-10">
                      Have a question? <a
                        href="mailto:{{landingPageData?.inviteSupportEmail}}">{{landingPageData?.inviteSupportEmail}}</a>
                    </div>
                    <div>
                      <small>Note that your answers are used purely to identify if you meet the requirements of the
                        research and are kept confidential. We will ensure that we have your consent to pass your
                        answers
                        across to the client if required.</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--<div class="col-sm-2"></div>-->
          </div><!-- end container -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade bs-example-modal-md client-email" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true" style="display: none;" id="declineModal">
  <div class="modal-dialog modal-md" style="max-width: 400px;">
    <div class="modal-content">
      <div class="modal-header no-border ax-modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        <h4 class="modal-title">Decline This Survey</h4>
      </div>
      <div class="modal-body no-padding">
        <form class="ax-form ax-color-black" #declineForm="ngForm">
          <!-- <div *ngFor="let opt of landingPageData?.declineOptions;let i = index" class="radio radio-primary"
            style="margin-right: 15px;display: inline-block;">
            <input type="radio" name="declineOptions" id="bouncemail{{i}}" [value]="opt" [(ngModel)]="declineOption"
              required>
            <label class="control-label" for="bouncemail{{i}}">{{opt}}</label>
          </div> -->
          <div class="form-group">
            <select name="declineOptions" class="form-control" [(ngModel)]="declineOption"
              #declineOptionModel="ngModel">
              <option [ngValue]="null" disabled>Select a Reason</option>                         
              <option *ngFor="let opt of landingPageData?.declineOptions;let i = index">{{opt}}</option>
            </select>
          </div>
          <div *ngIf="declineOption == 'Other'" class="form-group">
            <label class="control-label ax-color-black w-100-pre">
              Decline Reason
              <span class="pull-right font-normal font-12">
                <span id="count" class="text-right">{{checkStringLength(declineReason)}}</span> of 50 characters
              </span>
              <span [hidden]="declineReasonModel.valid || !isSubmitForm">
                <span class="text-danger pull-right" [hidden]="!declineReasonModel.hasError('required')">Required
                  &#160;</span>
              </span>
            </label>
            <textarea name="declineReason" class="form-control" placeholder="Reason for decline (max 50 characters)"
              rows="3" [(ngModel)]="declineReason" #declineReasonModel="ngModel" maxlength="50"></textarea>
          </div>
          <hr style="margin-top: 0;margin-bottom: 15px;">
          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-cocktail font-16 btn-block" data-dismiss="modal" aria-hidden="true">Cancel</button>
            </div>
            <div class="col-md-6">
              <button class="btn btn-basil font-16 btn-block" (click)="declineSurvey()">Decline</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>