<div class="ax-menu-box-container job-tracking">
  <div class="form-group pull-left" style="margin-top: 14px;position: relative;width: 210px;">
    <div class="input-group" style="text-align: left !important;">
      <div class="input-group-btn">
        <button class="btn waves-effect waves-light btn-default text-left" type="button">
          <!-- <i class="fa fa-search"></i> -->
          Track
        </button>
      </div>
      <input auto-complete id="model4" [(ngModel)]="searchJobNo" name="jobno" [source]="jobNoAPI"
        no-match-found-text="No Data Found" list-formatter="jobNumberAndName" path-to-data="value"
        loading-text="Loading..." max-num-list="5" min-chars="4" class="form-control text-left no-border"
        placeholder="Job Number" autocomplete="off" (valueChanged)="startTrackingJob(searchJobNo)"
        [accept-user-input]="false" [open-on-focus]="false" />
      <div class="input-group-btn">
        <button class="btn waves-effect waves-light btn-default text-left" type="button" (click)="searchJobNo = ''">
          <i class="md md-close"></i>
        </button>
      </div>
    </div>
    <!-- <input type="text" name="searchjob" [(ngModel)]="searchJobNo" (ngModelChange)="searchJob()"
           class="form-control ax-table-x125" placeholder="Job Number"
           auto-complete [source]="searchJobResults" [max-num-list]="10" [min-chars]="2" 
           (valueChanged)="startTrackingJob(searchJobNo)" /> -->
    <!-- <ul class="dropdown-menu" [class.show]="searchJobResults?.length > 0">
      <ng-container *ngFor="let job of searchJobResults; let i = index">
        <ng-container *ngIf="i < 10">
          <li (click)="startTrackingJob(job)" class="ax-cursor-pointer">
            <a>{{job?.jobNumber}}-{{job?.jobName}}</a>
          </li>
        </ng-container>
      </ng-container>
    </ul> -->
  </div>

  <div class="ax-menu-box bg-lightblue pull-left text-left"
    style="margin-top: 14px; border-radius: 2px; color: #fff;width: 395px;" [class.bg-red]="!trackingJob"
    [class.bg-green]="trackingJob">
    <b>Tracking:</b>
    <!--   1237-17 Ram..-->
    <a class="ax-cursor-pointer hint--bottom" target="_blank" [routerLink]="['/job/edit', trackingJob.id]"
      *ngIf="trackingJob?.id" [attr.aria-label]="trackingJob?.jobNumberAndName" style="color: #444">
      <!-- {{trackingJob?.jobNumber}} -->
      &#160;{{(trackingJob?.jobNumberAndName.length>43)? (trackingJob?.jobNumberAndName |
      slice:0:43)+'..':(trackingJob?.jobNumberAndName)}}
    </a>

    <span *ngIf="!trackingJob">
      Currently the system is not tracking any Job.
    </span>

    <a *ngIf="trackingJob" class="ax-cursor-pointer pull-right text-white" (click)="stopTrackingJob()">
      <i class="md md-close"></i>
    </a>
  </div>
  <div class="ax-menu-box pull-left text-left" style="margin-left:10px;"></div>
  <div class="ax-menu-box bg-lightblue pull-left text-left" *ngIf="smsAutoWorking"
    style="margin-top: 14px; border-radius: 2px; color: #fff;width: 395px;" [class.bg-red]="!smsAutoWorking"
    [class.bg-green]="smsAutoWorking">
    <span *ngIf="smsAutoWorking">
      SMS Automation is working.
    </span>
    <span *ngIf="!smsAutoWorking">
      SMS Automation is not working.
    </span>
  </div>

  <!--  <a class="btn btn-danger btn-square"-->
  <!--     (click)="stopTrackingJob()" style="margin-top: 14px; padding: 6px 5px;">-->
  <!--    <i class="md md-close"></i>-->
  <!--  </a>-->
</div>