<div class="wrapper">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <ol class="breadcrumb breadcrumb-arrow bg-white box-card-shadow">
          <li><a [routerLink]="['/dashboard']" class="home-breadcrumb"><i class="md md-home"></i><span style="display: none;">Home</span></a></li>
          <li><a>Contact Us</a></li>
        </ol>
      </div>
      <div class="col-sm-12">
        <div class="card-box">
          <div class="col-md-12 no-padding">
            <form #form="ngForm">
              <div class="form-group no-padding required">
                <label class="control-label w-100-pre" for="subject">Subject
                  <div class="pull-right">
                    <span [hidden]="subjectModel.valid || !isSubmitForm">
                      <span class="text-danger pull-right" [hidden]="!subjectModel.hasError('required')">Required</span>
                    </span>
                  </div>
                </label>
                <input type="text" class="form-control" name="subject" [(ngModel)]="subject" required
                  #subjectModel="ngModel" id="subject" autocomplete="on">
              </div>
              <div class="clearfix"></div>
              <div class="form-group no-padding required">
                <label class="control-label w-100-pre" for="message">Message
                  <div class="pull-right">
                    <span [hidden]="messageModel.valid || !isSubmitForm">
                      <span class="text-danger pull-right" [hidden]="!messageModel.hasError('required')">Required</span>
                    </span>
                  </div>
                </label>
                <textarea #messageModel="ngModel" name="message" rows="5" [(ngModel)]="message" class="form-control"
                  required id="message" autocomplete="on" ></textarea>
              </div>
              <div class="clearfix"></div>
              <div class="col-md-12 no-padding m-b-0">
                <div class="col-md-6 pull-left no-padding text-success">
                  {{successMsg}}
                </div>
                <div class="col-md-6 text-right no-padding">
                  <button type="button" (click)="clearContactForm()"
                    class="btn btn-cocktail waves-effect waves-light btn-md">
                    Clear
                  </button>&#160;
                  <button type="submit" (click)="submitContact(form)"
                    class="btn btn-basil waves-effect waves-light btn-md">
                    Submit
                  </button>
                </div>
              </div>
              <div class="clearfix"></div>
            </form>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</div>