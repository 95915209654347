﻿<div class="no-padding home-bg" style="margin-bottom: -61px;">
  <div class="home-bg-trans1">
    <!-- Navigation Bar-->
    <header id="topnav">
      <div class="topbar-main" style="height: 60px;">
        <div class="container">
          <!-- Logo container-->
          <div class="logo">
            <a class="logo" [title]="isFarronResearch ? 'Farron Research Logo' : 'Logo'">
              <span class="hidden-xs"><img src="assets/images/fr-logo.png"
                  [alt]="isFarronResearch ? 'Farron Research Logo' : 'Logo'"></span>
              <span class="hidden-sm hidden-md hidden-lg">
                <img src="assets/images/fr-only-logo.png" [alt]="isFarronResearch ? 'Farron Research Logo' : 'Logo'">
              </span>
            </a>
          </div>
          <!-- End Logo container-->
          <div class="menu-extras">
            <ul class="nav navbar-nav navbar-right pull-right ax-right-nav-btn">
              <li>
                <div class="input-group">
                  <a class="btn btn-basil" [routerLink]="['/signin']">Login</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- End topbar -->
    </header>
    <!-- End Navigation Bar-->
    <!-- =======================
          ===== START PAGE ======
          ======================= -->

    <div class="wrapper login-page-wrapper">
      <div class="container min-width-100vw text-center">
        <div class="row">
          <!--<div class="col-sm-2"></div>-->
          <div class="ax-login-panel">
            <h3 class="login-card-header">
              <i class="fa fa-lock"></i> &#160;Forgot your password?
            </h3>
            <div class="col-md-12">
              <div class="card-box m-b-0 login-card">
                <form class="form-horizontal ax-form" role="form" id="loginForm" #forgotPassForm="ngForm">
                  <div class="form-group">
                    <label for="emailAddress" class="d-none">Email Address</label>
                    <input type="text" name="username" class="form-control"
                      [class.invalid-field]="!emailModel.valid && isSubmitForm"
                      [placeholder]="role == 'Respondent' ? 'Email Address / Mobile Number' : 'Email Address'"
                      #emailModel="ngModel" [(ngModel)]="emailAddress" autocomplete="off" required id="emailAddress">
                  </div>
                  <!-- (blur)="checkDuplicateUser()" -->
                  <div class="form-group" *ngIf="isDuplicateUser && emailAddress && role == 'Respondent'">
                    <!-- <input type="text" id="mobile" name="mobile" class="form-control" [class.invalid-field]="!mobileModel.valid && isSubmitForm"
                              mask="0000 000 000" pattern=".{10,10}" placeholder="Mobile Number" [(ngModel)]="loginUser.mobileNo"
                              #mobileModel="ngModel" required autocomplete="off"> -->
                    <div class="col-md-6 no-padding pull-left">
                      <input type="text" class="form-control" name="firstname" [(ngModel)]="firstName"
                        placeholder="First Name" required [class.invalid-field]="!firstNameModel.valid && isSubmitForm"
                        #firstNameModel="ngModel">
                    </div>
                    <div class="col-md-6 no-padding-right pull-left">
                      <input type="text" class="form-control" name="lastname" [(ngModel)]="lastName"
                        placeholder="Last Name" required [class.invalid-field]="!lastNameModel.valid && isSubmitForm"
                        #lastNameModel="ngModel">
                    </div>
                  </div>


                  <!-- (click)="resetPassword(forgotPassForm)" -->
                  <div class="clearfix"></div>
                  <div class="text-right">
                    <button class="btn btn-basil waves-effect waves-light btn-md"
                      (click)="checkDuplicateUser(forgotPassForm)">
                      Reset Password
                    </button>
                    <button type="button" class="btn btn-cocktail m-l-5" [routerLink]="['/signin']">Cancel</button>
                  </div>
                  <div class="clearfix"></div>
                  <div tabindex="0" class="text-center error-box" *ngIf="error">
                    <b>{{error}}</b>
                  </div>
                  <div class="clearfix"></div>
                </form>
              </div>
            </div>
          </div>
          <!--<div class="col-sm-2"></div>-->
        </div><!-- end container -->
      </div>
    </div>
  </div>
</div>